<template>
    <div class="Vacancy-SelectBox">
      <multiselect
        v-model="department"
        :options="departments"
        label="name"
        :placeholder="value.name"
        :allow-empty="false"
        :searchable="false"
        class="Btn Btn--orange Btn--text-white Btn--text-bold Vacancy-Select"
      ></multiselect>
      <img src="@/assets/img/select-arrow.svg" alt="" class="Vacancy-SelectArrow">
    </div>
</template>

<script>
export default {
  name: "VacancySwitcherMobile",
  props: {
    value: {
      type: Object,
      require: true,
    },
    departments: {
      type: Array,
      require: true,
    },
  },
  computed: {
    department: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  }
}
</script>

<style lang="scss">
.Vacancy {
  &-Select {
    margin-bottom: 38px;
    font-size: 14px;
    letter-spacing: 0.02em;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:hover {
      border: 1px solid var(--color-bg-main);
      background-color: var(--color-bg-main);
    }
  }

  .multiselect__content-wrapper {
    border: none;
    max-height: 100% !important;
  }

  .multiselect__tags {
    padding: 16px;
  }

  .multiselect__input {
    //padding: 16px;
    outline: none;
    border: none;
    background-color: var(--color-bg-main);

    &::placeholder {
      font-family: 'Mont', sans-serif;
      font-weight: 800 !important;
      font-size: 14px;
      letter-spacing: 0.02em;
      color: var(--color-text-main);
    }
  }

  &-SelectBox {
    position: relative;
  }

  &-SelectArrow {
    position: absolute;
    top: 22px;
    right: 20px;
    transition: .3s;
  }

  .multiselect__content {
    display: block !important;
    position: absolute;
    background-color: var(--color-bg-main);
    z-index: 2;
    border: none;
    right: 0;
    left: 0;
  }

  .multiselect__element {
    width: 100%;
    border: 1px solid var(--color-bg-main);
    background-color: var(--color-body);
    color: var(--color-text-main3);

  }

  .multiselect__option {
    display: block;
    padding: 16px;
  }

  .multiselect--active + &-SelectArrow {
    transform: rotateZ(-180deg);
  }
}
</style>
