<template>
  <section class="Fone Facts">
    <div class="Container">
      <h3 class="Title--h3 Title--white Facts-Title">
        {{ $t('facts.title') }}
      </h3>
      <div class="Facts-List">
        <div v-for="(item, i) in mergeArrays"
             :key="i"
             class="Facts-Item">
          <img :src="require(`@/assets/img/${item.img}`)" alt="icon" class="Facts-ItemImg">
          <div class="Title--white Facts-ItemTitle">
            {{ item.title }}
          </div>
          <p class="Text Text--white Facts-ItemText">
            {{ item.text }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Facts",
  data() {
    return {}
  },
  computed: {
    facts() {
      return this.$store.getters.getFacts
    },
    mergeArrays() {
      let mergeArr = this.facts.map((info, index) => ({...info, ...this.$t('facts.facts')[index]}));
      return mergeArr
    }
  }
}
</script>

<style lang="scss">
.Facts {
  padding: 103px 0 60px;

  @media(min-width: $screen-l) {
    padding: 125px 0 115px;
  }

  &-Title {
    margin-bottom: 60px;
    text-align: center;
  }

  &-List {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 34px 0;

    @media(min-width: $screen-s) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 76px 40px;
    }

    @media(min-width: $screen-xl) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &-Item {
    text-align: center;
  }

  &-ItemImg {
    width: 80px;
    margin-bottom: 16px;
    @media(min-width: $screen-xl) {
      width: 90px;
      margin-bottom: 20px;
    }
  }

  &-ItemTitle {
    margin-bottom: 5px;
    font-weight: 800;
    font-size: 16px;
    letter-spacing: 0.02em;

    @media(min-width: $screen-xl) {
      font-size: 22px;
    }
  }
}
</style>