<template>
  <section class="Office" :class="{'Fone': width < 1262}">
    <div :class="{'Office-Container': width < 1262}">
      <div class="Office-About--flexend Office-About">
        <div class="Office-AboutInfo">
          <h3 class="Title--h3 Office--paddingleft Office-AboutInfoTitle"
              :class="width < 1262 ? 'Title--white' : 'Title--blue'"
          >
            {{ $t('aboutOffice.title1') }}
          </h3>
          <p class="Text Office--paddingleft Office-AboutInfoText"
             :class="width < 1262 ? 'Text--white' : 'Text--blue'"
          >
            {{ $t('aboutOffice.text1') }}
          </p>
          <img src="@/assets/img/marfatech.png" alt="photo" class="Office-AboutInfoImg">
        </div>
        <img src="@/assets/img/office.jpg" alt="photo" class="Office-AboutImg">
      </div>
      <div :class="{'Office-About--reverse': width < 1262}"
           class="Office-About"
      >
        <img src="@/assets/img/tennis.jpg" alt="photo" class="Office-AboutImg">
        <div class="Office-AboutInfo--marginleft Office-AboutInfo">
          <h3 class="Title--h3 Office--paddingright Office-AboutInfoTitle"
              :class="width < 1262 ? 'Title--white' : 'Title--blue'"
          >
            {{ $t('aboutOffice.title2') }}
          </h3>
          <p class="Text Office--paddingright Office-AboutInfoText"
             :class="width < 1262 ? 'Text--white' : 'Text--blue'"
          >
            {{ $t('aboutOffice.text2') }}
          </p>
          <p class="Text Office--paddingright Office-AboutInfoText"
             :class="width < 1262 ? 'Text--white' : 'Text--blue'"
          >
            {{ $t('aboutOffice.text3') }}
          </p>
          <ul class="Office-AboutInfoList">
            <li v-for="(item, i) in $t('aboutOffice.list')"
                :key="i"
                class="Text--orange Office-AboutInfoItem">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "Office",
  data() {
    return {
      width: '0',
      isWidth: false,
      items: [
        "Зона отдыха и пинг-понг",
        "Свободное размещение на мягких креслах",
        "Кухня со свежими фруктами и сладостями каждый день"
      ]
    }
  },
  methods: {},
  mounted() {
    this.width = window.innerWidth;
  }
}
</script>

<style lang="scss">
.Office {
  margin: 0 auto;
  padding: 40px 0 40px;

  @media(min-width: $screen-xl) {
    padding: 0;
  }

  &-Container {
    max-width: 320px;
    width: 320px;
    padding: 0 16px;
    margin: 0 auto;
    @media(min-width: $screen-xl) {
      max-width: 1262px;
      padding: 0 15px;
    }
  }

  &-About {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 26px;

    @media(min-width: $screen-xl) {
      flex-direction: row;
      align-items: flex-start;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--reverse {
      flex-direction: column-reverse;
    }
  }

  &-About--flexend {
    align-items: center;
    @media(min-width: $screen-xl) {
      align-items: flex-end;
    }
  }

  &-AboutInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;

    @media(min-width: $screen-xl) {
      width: 700px;
      margin-right: 40px;
      margin-bottom: 0;
      padding-top: 60px;
    }

    &--marginleft {
      @media(min-width: $screen-xl) {
        margin-right: 0;
        margin-left: 40px;
      }
    }
  }

  &--paddingleft {

    @media(min-width: $screen-xl) {
      padding-left: 104px;
    }
  }

  &--paddingright {

    @media(min-width: $screen-xl) {
      padding-right: 104px;
    }
  }

  &-AboutInfoTitle {
    margin-bottom: 22px;
    text-align: center;

    @media(min-width: $screen-xl) {
      margin-bottom: 41px;
      text-align: left;
    }
  }

  &-AboutInfoText {
    margin-bottom: 26px;

    @media(min-width: $screen-xl) {
      margin-bottom: 60px;
    }

    &:nth-child(3) {
      margin-bottom: 10px;
      @media(min-width: $screen-xl) {
        margin-bottom: 20px;
      }
    }
  }

  &-AboutInfoList {

    @media(min-width: $screen-xl) {
      padding-right: 180px;
    }
  }

  &-AboutInfoItem {
    margin-bottom: 10px;
    padding-left: 7px;
    position: relative;
    font-weight: 800;
    font-size: 12px;
    letter-spacing: 0.02em;

    @media(min-width: $screen-xl) {
      margin-bottom: 20px;
      padding-left: 27px;
      font-size: 20px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      top: 4px;
      left: 0;
      background-color: var(--color-bg-main);
      border-radius: 50%;

      @media(min-width: $screen-xl) {
        left: 13px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-AboutImg {
    max-width: 700px;
    width: 100%;
  }
}
</style>