<template>
    <div v-if="!isMobile" class="Vacancy-BtnsBox">
      <div
          class="Vacancy-Btns"
      >
        <div class="Btn Btn--text-orange Btn--text-bold Vacancy-Btn"
            v-for="(button, i) in departments"
            :key="i"
        >
          <input
            :id="button"
            class="Vacancy-Input"
            type="radio"
            v-model="department"
            :value="button"
          >
          <label class="Vacancy-Label" :for="button">
            {{ button }}
          </label>
        </div>
      </div>
    </div>
    <div v-else class="Vacancy-SelectBox">
      <multiselect
        v-model="department"
        :options="departments"
        label="name"
        :placeholder="department"
        :allow-empty="false"
        :searchable="false"
        class="Btn Btn--orange Btn--text-white Btn--text-bold Vacancy-Select"
      ></multiselect>
      <img src="@/assets/img/select-arrow.svg" alt="" class="Vacancy-SelectArrow">
    </div>
</template>

<script>
export default {
  name: "VacancySwitcher",
  props: {
    value: {
      type: String,
      require: true,
    },
    departments: {
      type: Array,
      require: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    department: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  }
}
</script>

<style lang="scss">
.Vacancy {
  &-Btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
    margin-bottom: 38px;
  }

  &-Btn {
    width: 20%;
    font-size: 18px;
    letter-spacing: 0.02em;
  }

  &-Label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 26px 10px;
    cursor: pointer;
  }

  &-Input {
    display: none;
    position: absolute;
    visibility: hidden;
    z-index: -9999;
    left: -9999;
    right: -9999;
  }

  input:checked + label {
    background-color: var(--color-bg-hover);
    color: var(--color-text-main);
  }
}
</style>
