<template>
  <div class="Vacancy-List">
    <div class="Vacancy-Item"
      v-for="(item, i) in filteredVacancies"
      :key="i"
    >
      <div
        class="Vacancy-ItemTitle"
        :class="theme === 'light' ? 'Text--blue' : 'Text--white'"
      >
        {{ item.name }}
      </div>
      <div
        class="Text Vacancy-ItemText"
        :class="theme === 'light' ? 'Text--blue' : 'Text--white'"
        v-html="item.what_to_do"
      ></div>
      <LocalizedLink
        :to="`vacancy-detail/${item._id}`"
        class="Btn Btn--text-orange Vacancy-ItemLink"
      >
        {{ $t('vacancy.more') }}
      </LocalizedLink>
    </div>
  </div>
</template>

<script>
import LocalizedLink from "@/components/LocalizedLink";

export default {
  name: "VacancyList",
  components: {
    LocalizedLink,
  },
  props: {
    filteredVacancies: {
      type: Array,
      required: true,
    },
    theme: {
      type: String,
      required: true,
      default: 'light',
    },
    isShort: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss">
.Vacancy {
  &-List {
    min-height: 280px;
  }

  &-Item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 27px;
    padding-bottom: 25px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      background-color: var(--color-bg-ghost);
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;

      &:before {
        display: none;
      }
    }

    @media(min-width: $screen-xl) {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 21px;
      padding-bottom: 20px;
      &:before {
        width: 85%;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }

  &-ItemTitle {
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 14px;
    line-height: 1.52;

    @media(min-width: $screen-xl) {
      width: 290px;
      margin-bottom: 0;
      font-size: 22px;
    }
  }

  &-ItemText {
    margin-bottom: 16px;
    @media(min-width: $screen-xl) {
      width: 670px;
      margin-bottom: 0;
    }
  }

  &-ItemLink {
    padding: 12px 40px;
    font-size: 12px;
    @media(min-width: $screen-xl) {
      padding: 9px 30px;
      font-size: 16px;
    }
  }

  &-Link {
    padding: 24px 85px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    @media(min-width: $screen-xl) {
      padding: 20px 77px;
    }
  }
}
</style>
