<template>
  <section class="Header">
    <div class="Container">
      <img v-if="$screen.width < 1262" src="@/assets/img/header-full-bg.svg" alt="bg" class="Header-ImgBg">
      <img v-else src="@/assets/img/header-radian-bg.svg" alt="bg" class="Header-ImgBg">
      <img
          v-for="(planet, i) in planets"
           :key="i"
           :src="require(`@/assets/img/${planet.img}`)" :alt="planet.class"
           :class="planet.class"
           class="Planet"
      >
      <h1 class="Title Title--blue Header-Title">
        {{$t('title') }}
      </h1>
      <div class="Header-Content">
        <div class="Header-ContentInner">
          <p class="Text Text--blue Header-ContentText">
            {{$t('header.text') }}
          </p>
          <div class="Header-ContentList">
            <div
                v-for="(text, i) in $t('header.list')"
                :key="i"
                class="Text Text--orange Header-ContentItem"
            >
              {{ text }}
            </div>
          </div>
          <div class="Text Text--blue Header-ContentDate">
            {{ $t('header.date') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      planets: [
        {img: "mercury.svg", class: "Mercury"},
        {img: "venus.svg", class: "Venus"},
        {img: "earth.svg", class: "Earth"},
        {img: "mars.svg", class: "Mars"},
        {img: "jupiter.svg", class: "Jupiter"},
        {img: "saturn.svg", class: "Saturn"},
        {img: "uranus.svg", class: "Uranus"},
        {img: "neptune.svg", class: "Neptune"},
      ]
    }
  },
  computed: {
    nav() {
      return this.$store.getters.getNavLinks
    }
  },
}
</script>

<style lang="scss">
.Header {
  overflow: hidden;
  padding: 119px 0 24px;

  @media(min-width: $screen-xl) {
    padding: 248px 0 128px;
  }

  .Container {
    position: relative;
  }

  &-ImgBg {
    max-width: 484px;
    position: absolute;
    top: -195px;
    left: -184px;
    z-index: -1;

    @media(min-width: $screen-xl) {
      max-width: 1398px;
      top: -618px;
      left: -412px;
    }
  }

  &-Title {
    margin-bottom: 48px;
    font-size: 42px;
    line-height: 2.38;
    letter-spacing: 0.02em;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 56px;
      height: 56px;
      background-color: var(--color-bg-main);
      border-radius: 50%;
      z-index: -1;
      top: 19px;
      left: 14px;
    }

    @media(min-width: $screen-s) {
      padding-left: 21px;
      text-align: left;
    }

    @media(min-width: $screen-xl) {
      margin-bottom: 71px;
      padding-left: 0;
      text-align: center;
      font-size: 140px;
      line-height: 1.3;

      &:before {
        width: 206px;
        height: 206px;
        top: -22px;
        left: 171px;
      }
    }
  }

  &-Content {
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
  }

  &-ContentInner {
    width: 229px;

    @media(min-width: $screen-xl) {
      width: 579px;
    }
  }

  &-ContentText {
    margin-bottom: 16px;

    @media(min-width: $screen-xl) {
      margin-bottom: 48px;
    }
  }

  &-ContentList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;

    @media(min-width: $screen-xl) {
      flex-wrap: nowrap;
      margin-bottom: 42px;
    }
  }

  &-ContentItem {
    margin-right: 11px;

    &:last-child {
      margin-right: 0;
    }

    @media(min-width: $screen-xl) {
      margin-right: 20px;
    }
  }

  &-ContentDate {
    font-weight: 900;
  }

  .Planet {
    display: none;
    @media(min-width: $screen-xl) {
      display: block;
      position: absolute;
    }
  }

  .Mercury {
    top: 188px;
    left: 396px;
    animation: moving-right 6s infinite linear;
    transform-origin: -108px -108px;
  }

  .Venus {
    top: 220px;
    left: 427px;
    animation: moving-left 12s infinite linear;
    transform-origin: -140px -140px;
  }

  .Earth {
    top: 266px;
    left: 472px;
    animation: moving-right 14s infinite linear;
    transform-origin: -185px -185px;
  }

  .Mars {
    top: 308px;
    left: 514px;
    animation: moving-left 16s infinite linear;
    transform-origin: -228px -228px;
  }

  .Jupiter {
    top: 360px;
    left: 570px;
    animation: moving-right 18s infinite linear;
    transform-origin: -280px -280px;
  }

  .Saturn {
    top: 422px;
    left: 630px;
    animation: moving-left 20s infinite linear;
    transform-origin: -343px -343px;
  }

  .Uranus {
    top: 503px;
    left: 711px;
    animation: moving-right 28s infinite linear;
    transform-origin: -425px -425px;
  }

  .Neptune {
    top: 557px;
    left: 769px;
    animation: moving-right 24s infinite linear;
    transform-origin: -482px -482px;
  }

  @keyframes moving-left {
    100% {
      transform: rotate(-360deg);
    }
  }

  @keyframes moving-right {
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>