<template>
  <div>
    <h2 class="Title Title--h2 Title--orange Vacancy-Title">
      {{ $t('vacancy.title') }}
    </h2>
    <h3 class="Title--h3 Vacancy-SubTitle"
        :class="theme === 'light' ? 'Title--blue' : 'Title--white'"
    >
      {{ $t('vacancy.subtitle') }}
    </h3>
  </div>
</template>

<script>
export default {
  name: "VacancyHeader",
  props: {
    theme: {
      type: String,
      required: true,
    },
  }
}
</script>

<style lang="scss">
.Vacancy {
  &-Title {
    margin-bottom: 10px;
    @media(min-width: $screen-xl) {
      margin-bottom: 26px;
    }
  }

  &-SubTitle {
    margin-bottom: 26px;
    text-align: center;
    font-size: 16px;

    @media(min-width: $screen-xl) {
      margin-bottom: 36px;
      font-size: 36px;
    }
  }
}
</style>
