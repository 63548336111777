<template>
  <section class="VacancyDetail">
    <Spinner v-if="isVacanciesLoading" />
    <div v-else class="Container">
      <h3 class="Title--h3 Title--orange VacancyDetail-SubTitle">
        {{ $t('vacancy.title') }}
      </h3>
      <h2 class="Title Title--h2 Title--blue VacancyDetail-Title">
        {{ vacancy.name }}
      </h2>
      <div class="VacancyDetail-Inner">
        <div class="VacancyDetail-Content VacancyDetail-Content--left">
          <router-link :to="{ path: '/vacancy', hash: '#vacancy' }" class="Btn Btn--orange Btn--text-white VacancyDetail-Link">
            {{ $t('dynamicVacancyPage.button') }}
          </router-link>
        </div>
        <div class="VacancyDetail-Content VacancyDetail-Content--right">
          <div class="VacancyDetail-Content VacancyDetail-Content--right">
            <div
              class="VacancyDetail-Description"
              v-html="vacancy.description"
            ></div>
            <h4 v-if="vacancy.what_to_do" class="Title--h4 Title--orange VacancyDetail-TitleH4">
              {{ $t('vacancy.whatToDo') }}
            </h4>
            <div
              class="VacancyDetail-WhatToDo"
              v-html="vacancy.what_to_do"
            ></div>
            <h4 v-if="vacancy.expect" class="Title--h4 Title--orange VacancyDetail-TitleH4">
              {{ $t('vacancy.expect') }}
            </h4>
            <div
              class="VacancyDetail-WhatToDo"
              v-html="vacancy.expect"
            ></div>
            <h4 v-if="vacancy.advantages" class="Title--h4 Title--orange VacancyDetail-TitleH4">
              {{ $t('vacancy.advantages') }}
            </h4>
            <div
              class="VacancyDetail-Advantages"
              v-html="vacancy.advantages"
            ></div>
            <h4 v-if="vacancy.offer" class="Title--h4 Title--orange VacancyDetail-TitleH4">
              {{ $t('vacancy.offer') }}
            </h4>
            <div
              class="VacancyDetail-Offer"
              v-html="vacancy.offer"
            ></div>
          </div>
       </div>
      </div>
    </div>
    <Form/>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Form from "../../components/Form";
import Spinner from "@/components/Spinner";

export default {
  name: "VacancyDetail",
  components: {
    Form,
    Spinner
  },
  computed: {
    ...mapState({
      isVacanciesLoading: 'isVacanciesLoading'
    }),
    ...mapGetters(['getVacancyById']),
    vacancyId() {
      return this.$route.params.id;
    },
    vacancy() {
      return this.$store.getters.getVacancyById(this.vacancyId);
    }
  },
  methods: {
    ...mapActions({ getVacanciesList: 'getVacanciesList' })
  },
  beforeMount() {
    this.getVacanciesList();
  }
}
</script>

<style lang="scss">
.VacancyDetail {
  padding: 110px 0 80px;

  @media(min-width: $screen-xl) {
    padding: 200px 0 100px;
  }

  &-SubTitle {
    margin-bottom: 7px;
    text-align: center;
    letter-spacing: 0.02em;
    @media(min-width: $screen-xl) {
      margin-bottom: 19px;
    }
  }

  &-Title {
    margin-top: 30px;
    margin-bottom: 46px;
    text-align: center;
    @media(min-width: $screen-xl) {
      margin-bottom: 140px;
    }
  }

  &-Inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media(min-width: $screen-xl) {
      flex-direction: row;
      justify-content: space-between;
      padding-right: 114px;
    }
  }

  &-Content {

    &--left {
      @media(min-width: $screen-xl) {
        width: 385px;
        padding-top: 25px;
      }
    }

    &--right {
      @media(min-width: $screen-xl) {
        width: 702px;
      }
    }
  }

  &-TitleH3 {
    margin-bottom: 26px;
    @media(min-width: $screen-xl) {
      margin-bottom: 49px;
    }
  }

  &-Address {
    margin-bottom: 26px;
    @media(min-width: $screen-xl) {
      margin-bottom: 50px;
    }
  }

  &-Link {
    margin-bottom: 46px;
    padding: 24px 82px;
    @media(min-width: $screen-xl) {
      margin-bottom: 0;
      padding: 20px 59px;
    }
  }

  &-Text {
    margin-bottom: 20px;
    @media(min-width: $screen-xl) {
      margin-bottom: 24px;
    }
  }

  &-TitleH4 {
    margin: 20px 0;
    @media(min-width: $screen-xl) {
      margin: 24px 0;
    }
  }

  &-List {
    margin-bottom: 20px;
    @media(min-width: $screen-xl) {
      margin-bottom: 24px;
    }
  }

  &-Item {
    position: relative;
    margin-bottom: 16px;
    padding-left: 10px;

    @media(min-width: $screen-xl) {
      margin-bottom: 20px;
      padding-left: 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      width: 3px;
      height: 3px;
      background-color: var(--color-text-main2);
      top: 8px;
      left: 0;
    }
  }
  p, li {
    font-size: 12px;
    letter-spacing: 0.02em;
    @media(min-width: $screen-xl) {
      font-size: 18px;
    }
  }

  p {
    margin-bottom: 20px;
  }

  li {
    list-style-type: disc;
    padding-left: 10px;
    margin-bottom: 10px;
  }
}
</style>
