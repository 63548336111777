<template>
  <section class="Vacancy" id="vacancy">
    <div class="Container">
      <VacancyHeader :theme="theme" />
      <template v-if="!isShort">
        <VacancySwitcher
          v-if="!isMobile"
          v-model="selectedDepartment"
          :departments="availableDepartments"
          :isMobile="isMobile"
        />
        <VacancySwitcherMobile
          v-else
          v-model="selectedDepartmentMobile"
          :departments="availableDepartmentsMobile"
          :isMobile="isMobile"
        />
      </template>
      <VacancyList
        :filteredVacancies="filteredVacancy"
        :theme="theme"
      />
      <LocalizedLink
        v-if="isShort"
        to="vacancy"
        class="Btn Btn--orange Btn--text-white Vacancy-Link"
      >
        {{ $t('vacancy.button') }}
      </LocalizedLink>
    </div>
  </section>
</template>

<script>
import LocalizedLink from "@/components/LocalizedLink";
import VacancyHeader from "./VacancyHeader.vue";
import VacancySwitcher from "./VacancySwitcher.vue";
import VacancySwitcherMobile from "./VacancySwitcherMobile.vue";
import VacancyList from "./VacancyList.vue";
import {mapGetters} from "vuex";

export default {
  name: "Vacancies",
  components: {
    LocalizedLink,
    VacancyHeader,
    VacancySwitcher,
    VacancySwitcherMobile,
    VacancyList,
  },
  props: {
    vacancies: {
      type: Array,
      required: true,
    },
    theme: {
      type: String,
      required: true,
      default: 'light',
    },
    isShort: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedDepartment: '',
      selectedDepartmentMobile: {},
    }
  },
  computed: {
    ...mapGetters(['getVacancies', 'availableDepartments']),
    filteredVacancy() {
      if (this.isShort) {
        return this.vacancies;
      }
      if (this.isMobile) {
        return this.vacancies.filter(vacancy => vacancy.department === this.selectedDepartmentMobile.name);
      }
      return this.vacancies.filter(vacancy => vacancy.department === this.selectedDepartment);
    },
    availableDepartmentsMobile() {
      return this.availableDepartments.map(department => ({ name: department }));
    },
    isMobile() {
      return this.$screen.width < 1262;
    }
  },
  mounted() {
    this.selectedDepartment = this.vacancies?.[0]?.department;
    this.selectedDepartmentMobile = { name: this.vacancies?.[0]?.department };
  },
}
</script>

<style lang="scss">
.Vacancy {
  padding: 40px 0 60px;

  @media(min-width: $screen-xl) {
    padding: 110px 0 80px;
  }
}
</style>
