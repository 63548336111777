<template>
  <section class="Contacts">
    <div class="Contacts-Container">
      <h2 class="Title Title--h2 Title--blue Contacts-Title">
        {{ $t('contacts.title') }}
      </h2>
<!--      <div class="Text Text&#45;&#45;blue Contacts-Address">-->
<!--        Россия, г. Москва, Большая Тульская ул., д. 11-->
<!--      </div>-->
      <div class="Contacts-List">
        <a v-for="(contact, i) in contacts"
           :key="i"
           :href="contact.href" class="Contacts-Item">
          <img :src="require(`@/assets/img/${contact.icon}`)" alt="" class="Contacts-ItemImg">
          <span class="Text Title--blue Contacts-ItemText">
            {{ contact.text }}
          </span>
        </a>
      </div>
<!--      <h3 class="Title&#45;&#45;h3 Title&#45;&#45;blue Contacts-SubTitle">-->
<!--        Как нас найти-->
<!--      </h3>-->
<!--      <p class="Text Text&#45;&#45;blue Contacts-Path">-->
<!--        Вход со стороны Большой Тульской улицы, ТДК “Тульский”, в портал “Деловой центр”-->
<!--      </p>-->
<!--      <div class="Contacts-Map">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4495.570558016356!2d37.623956!3d55.710104!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b54b412688d9d9%3A0x1572b636f5eb31fc!2z0JHQvtC70YzRiNCw0Y8g0KLRg9C70YzRgdC60LDRjyDRg9C7LiwgMTEsINCc0L7RgdC60LLQsCwgMTE1MTkx!5e0!3m2!1sru!2sru!4v1628077184961!5m2!1sru!2sru"
             width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </div>
      <div id="map"></div>-->
    </div>
  </section>
</template>

<script>
export default {
  name: "Contacts",
  data() {
    return {
      contacts: [
        /*{href: "tel:+71234567800", icon: "phone.svg", text: "+7 123 456 78 00"},*/
        {href: "mailto:hello@marfa-tech.com", icon: "mail.svg", text: "hello@marfa-tech.com"},
        /*{href: "Marfatech", icon: "skype.svg", text: "Marfatech"},*/
      ]
    }
  },
}
</script>

<style lang="scss">
.Contacts {
  padding: 0 0 60px;

  @media(min-width: $screen-xl) {
    padding: 0 0 180px;
  }

  &-Container {
    max-width: 320px;
    width: 100%;
    padding: 0 16px;
    margin: 0 auto;

    @media(min-width: $screen-s) {
      max-width: 1054px;
      padding: 0 15px;
    }

  }

  &-Title {
    width: 180px;
    margin: 0 auto 28px;
    @media(min-width: $screen-xl) {
      width: 100%;
      margin-bottom: 54px;
    }
  }

  &-Address {
    width: 170px;
    margin-bottom: 14px;
    @media(min-width: $screen-xl) {
      width: 260px;
      margin-bottom: 44px;
    }
  }

  &-List {
    display: flex;
    flex-direction: column;
    margin-bottom: 46px;
    @media(min-width: $screen-xl) {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 60px;
    }
  }

  &-Item {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    @media(min-width: $screen-xl) {
      margin-right: 42px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-ItemImg {
    max-width: 19px;
    margin-right: 9px;
    @media(min-width: $screen-xl) {
      max-width: 29px;
      margin-right: 10px;
    }
  }

  &-ItemText {
    padding-top: 3px;
  }

  &-SubTitle {
    margin-bottom: 16px;
    @media(min-width: $screen-xl) {
      margin-bottom: 19px;
    }
  }

  &-Path {
    margin-bottom: 18px;
    @media(min-width: $screen-xl) {
      margin-bottom: 40px;
    }
  }

  &-Map {
    width: 100%;
    height: 261px;
    margin: 0 auto;
    @media(min-width: $screen-xl) {
      height: 374px;
    }
  }
}
</style>