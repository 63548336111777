<template>
  <div class="Spinner">
    <img class="Spinner-Img" src="@/assets/img/spinner.svg" alt="spinner">
  </div>
</template>

<script>
export default {
  name: "Spinner",
}
</script>

<style lang="scss">
.Spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px 0;

  &-Img {
    width: 80px;
  }
}
</style>
