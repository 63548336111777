<template>
  <section class="VacancyPage">
    <div class="VacancyPage-Top">
      <div class="Container">
        <h2 class="Title Title--h2 Title--blue VacancyPage-Title">
          {{ $t('vacancy.title') }}
        </h2>
        <p class="Text Text--blue VacancyPage-Text">
          {{ $t('vacancy.text') }}
        </p>
        <router-link :to="{ path: '/vacancy', hash: '#vacancy' }"
                class="Btn Btn--orange Btn--text-white VacancyPage-Btn">
          {{ $t('vacancy.button') }}
        </router-link>
      </div>
    </div>
    <Facts />
    <Spinner v-if="isVacanciesLoading" />
    <Vacancies
      v-else
      id="vacancy"
      :vacancies="vacanciesList"
      theme="light"
    />
    <Office />
    <Form />
  </section>
</template>

<script>
import Facts from "@/components/Facts";
import Form from "@/components/Form";
import Office from "@/components/Office";
import Vacancies from "@/components/Vacancies";
import Spinner from "@/components/Spinner";
import { mapActions, mapState } from 'vuex';
export default {
  name: "VacancyPage",
  components: {Vacancies, Office, Form, Facts, Spinner},
  computed: {
    ...mapState({
      vacanciesList: 'vacanciesList',
      isVacanciesLoading: 'isVacanciesLoading'
    }),
  },
  methods: {
    ...mapActions({ getVacanciesList: 'getVacanciesList' })
  },
  beforeMount() {
    this.getVacanciesList();
  }
}
</script>

<style lang="scss">
.VacancyPage {
    padding: 110px 0 80px;

    @media(min-width: $screen-xl) {
      padding: 200px 0 100px;
    }

    &-Top {
    margin-bottom: 67px;
    @media(min-width: $screen-xl) {
      margin-bottom: 125px;
    }
  }

  &-Title {
    margin-bottom: 45px;
    @media(min-width: $screen-xl) {
      margin-bottom: 65px;
    }
  }

  &-Text {
    margin-bottom: 56px;
    text-align: center;
    @media(min-width: $screen-xl) {
      margin-bottom: 75px;
      padding: 0 170px;
    }
  }

  &-Btn {
    position: relative;
    padding: 25px 82px;
    left: 50%;
    transform: translate(-50%, 0);
    @media (min-width: $screen-xl) {
      padding: 21px 69px;
    }
  }

}
</style>
