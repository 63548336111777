<template>
  <section class="Form" :class="{'Form--padding': $route.name === 'contacts'}">
    <div class="Container">
      <h2 class="Title Title--h2 Title--blue Form-Title">
        {{ $t('form.title') }}
      </h2>
      <p class="Text Text--blue Form-Text">
        {{ $t('form.text') }}
      </p>
      <form class="Form-Form" @submit.prevent="onSubmit()" novalidate>
        <div class="Form-Content">
          <div class="Form-FormLeft">
            <div class="Form-FieldGroup">
              <div class="Form-Field">
                <input type="text"
                       class="Form-Input Input"
                       name="name"
                       :placeholder="$t('form.placeholder.name')"
                       v-model.trim="form.name"
                       :class="$v.form.name.$error ? 'is-invalid' : ''"
                >
                <span v-if="$v.form.name.$dirty && !$v.form.name.required"
                      class="invalid-feedback">{{ $t('form.errors.emptyField') }}</span>

              </div>
              <div class="Form-Field">
                <input type="text"
                       class="Form-Input Input"
                       name="surname"
                       :placeholder="$t('form.placeholder.surname')"
                       v-model.trim="form.surname"
                       :class="$v.form.surname.$error ? 'is-invalid' : ''"
                >
                <span v-if="$v.form.surname.$dirty && !$v.form.surname.required"
                      class="invalid-feedback">{{ $t('form.errors.emptyField') }}</span>
              </div>
              <div class="Form-Field">
                <input type="email"
                       class="Form-Input Input"
                       name="email"
                       :placeholder="$t('form.placeholder.email')"
                       v-model.trim="form.email"
                       :class="$v.form.email.$error ? 'is-invalid' : ''"
                >
                <span v-if="$v.form.email.$dirty && !$v.form.email.required"
                      class="invalid-feedback">{{ $t('form.errors.emptyField') }}</span>
                <span v-if="$v.form.email.$dirty && !$v.form.email.email"
                      class="invalid-feedback">{{ $t('form.errors.needEmail') }}</span>
              </div>
              <div class="Form-Field">
                <input type="text"
                       class="Form-Input Input"
                       name="phone"
                       :placeholder="$t('form.placeholder.phone')"
                       v-model.trim="form.phone"
                       :class="$v.form.phone.$error ? 'is-invalid' : ''"
                >
                <span v-if="$v.form.phone.$dirty && !$v.form.phone.required"
                      class="invalid-feedback">{{ $t('form.errors.emptyField') }}</span>
                <span
                    v-else-if="$v.form.phone.$dirty
                    && !$v.form.phone.phoneWithPlusCheck && $v.$dirty"
                    class="invalid-feedback">{{ $t('form.errors.incorrectPhone') }}</span>
              </div>
            </div>
            <div class="Form-Field">
              <input type="text"
                     class="Form-Input Input"
                     name="portfolio"
                     :placeholder="$t('form.placeholder.likResume')"
                     v-model.trim="form.portfolio"
              >
            </div>
            <div class="Form-Field">
              <textarea name="message"
                        class="Form-Textarea Input"
                        :placeholder="$t('form.placeholder.infoAbout')"
                        v-model.trim="form.message"
                        :class="$v.form.message.$error ? 'is-invalid' : ''"
              ></textarea>

              <span v-if="$v.form.message.$dirty && !$v.form.message.required"
                    class="invalid-feedback">{{ $t('form.errors.emptyField') }}</span>

            </div>
          </div>
          <div class="Form-FormRight">
            <div class="Form-Field">
              <input id="file"
                     type="file"
                     @change="addFile($event)"
                     class="Input--file"
              >
              <label v-if="!form.file.name" for="file"
                     class="Btn Form-Label"
              >{{ $t('form.placeholder.attachResume') }} {{ form.file.name }}</label>
              <label v-else for="file"
                     class="Btn Form-Label Form-Label--active"
              >{{ form.file.name }}</label>
              <span v-if="!form.file.name" class="Form-Span">
                {{ $t('form.placeholder.fileSize') }}
              </span>
            </div>

          </div>
        </div>
        <button type="submit"
                :disabled="isDisabled"
                :class="{ 'Btn--disabled': isDisabled}"
                class="Btn Btn--orange Btn--text-white Form-Btn">
          {{ $t('form.button') }}
        </button>
        <div class="Form-SendInfo"
             :class="{ 'Form-SendInfo--active': isDisabled}"
        >
          <p
              v-for="(text, i) in $t('form.sentInfo')"
              :key="i"
              class="Form-SendInfoText"
          >
            {{ text }}
          </p>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {email, required} from "vuelidate/lib/validators";
import {phoneWithPlusCheck} from '@/utils/formCheckers';
import axios from 'axios';

export default {
  mixins: [validationMixin],
  name: "Form",
  data() {
    return {
      form: {
        name: '',
        surname: '',
        email: '',
        phone: '',
        portfolio: '',
        message: '',
        file: '',
      },
      isDisabled: false,
    }
  },
  validations: {
    form: {
      name: {
        required,
      },
      surname: {
        required,
      },
      email: {
        required,
        email
      },
      phone: {
        required,
        phoneWithPlusCheck,
      },
      portfolio: {
        //required,
      },
      message: {
        required,
      },
      file: {
        //required,
      },
    }
  },

  methods: {
    addFile(event) {
      this.form.file = event.target.files[0]
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      const formData = new FormData();
      Object.keys(this.form).forEach(key => {
        return formData.append(key, this.form[key])
      })
      await axios.post(
          "/mail.php",
          formData,
          {
            headers: {
              'content-type': 'multipart/form-data'
            }
          }
      ).then(() => {
        Object.keys(this.form).forEach(key => this.form[key] = '')
        this.isDisabled = true
        this.$nextTick(() => {
          this.$v.$reset()
        })
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss">
.Form {
  padding: 66px 0 60px;

  @media(min-width: $screen-xl) {
    padding: 100px 0;

    &--padding {
      padding: 150px 0 100px;
    }
  }

  &-Title {
    margin-bottom: 10px;
  }

  &-Text {
    margin-bottom: 30px;
    text-align: center;
  }

  &-Form {
    /* margin: 0 auto;
     max-width: 100%;
     width: 100%;*/
    @media(min-width: $screen-xl) {
      max-width: 1024px;
      width: 100%;
      margin: 0 auto;
    }
  }

  &-Content {
    display: flex;
    flex-direction: column;
    margin-bottom: 51px;
    @media(min-width: $screen-xl) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &-FormLeft {
    width: 100%;
    margin-bottom: 16px;
    @media(min-width: $screen-xl) {
      max-width: 608px;
      margin-bottom: 0;
    }
  }

  &-FormRight {
    width: 100%;
    @media(min-width: $screen-xl) {
      max-width: 296px;
    }
  }

  &-FieldGroup {
    @media(min-width: $screen-xl) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0 16px;
    }
  }

  &-Field {
    width: 100%;
    max-width: 100%;
    margin-bottom: 16px;
    position: relative;
  }

  &-Input {
    max-width: 100%;
    width: 100%;
    padding: 22px 19px;
    border: 1px solid var(--color-border);
    outline: none;
    font-size: 16px;
    line-height: 1.25;

    &::placeholder {
      font-weight: 400;
      color: var(--color-text-placeholder);
    }

    &:focus {
      border: 2px solid var(--color-border);
      padding: 21px 18px;
    }
  }

  .Input {
    //padding: 19px;
    background-color: var(--color-body);
  }

  &-Textarea {
    max-width: 100%;
    width: 100%;
    height: 160px;
    padding: 22px 19px;
    resize: none;
    outline: none;
    border: 1px solid var(--color-border);
    font-family: 'Mont', sans-serif;
    font-weight: 400;
    font-size: 16px;

    &::placeholder {
      font-family: 'Mont', sans-serif;
      font-weight: 400;
      color: var(--color-text-placeholder);
    }

    &:focus {
      border: 2px solid var(--color-border);
      padding: 21px 18px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-Btn {
    position: relative;
    padding: 21px 103px;
    left: 50%;
    transform: translate(-50%, 0);
    @media (min-width: $screen-xl) {
      padding: 21px 83px;
      left: 45.9%;
    }
  }

  .Input--file {
    position: absolute;
    z-index: -1;
    opacity: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }

  &-Label {
    display: block;
    padding: 25px 40px 21px 60px;
    font-size: 16px;
    color: var(--color-text-main3);
    position: relative;
    transition: all .3s ease;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      width: 16px;
      height: 15px;
      top: 50%;
      left: 38px;
      transform: translate(0, -50%);
      background-image: url(~@/assets/img/clip-orange.svg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:hover {
      &:before {
        background-image: url(~@/assets/img/clip-white.svg);
      }
    }

    @media (min-width: $screen-xl) {
      padding: 25px 47px 21px 74px;
      &:before {
        left: 54px;
      }
    }
  }

  &-Label--active {
    background: var(--color-border2);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-text-main);

    &:before {
      display: none;
    }
  }

  &-Span {
    margin-top: 12px;
    font-size: 12px;
    color: var(--color-bg-main3);
    @media (min-width: $screen-xl) {
      margin-top: 10px;
      font-size: 14px;
    }
  }

  &-SendInfo {
    display: none;
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
    color: var(--color-text-main3);
    @media (min-width: $screen-xl) {
      margin-top: 30px;
      font-size: 20px;
    }
  }

  &-SendInfo--active {
    display: block;
  }

  .Btn--disabled {
    border-color: var(--color-bg-main3);
    background-color: var(--color-bg-main3);
  }
}

.is-invalid {
  border-color: var(--color-text-error);
  //&:focus {
  //  border-color: var(--color-text-error);
  //}
}

.is-invalid-label {
  border-color: var(--color-text-error);
  color: var(--color-text-error);

  &:before {
    background-image: url(~@/assets/img/clip-red.svg);
  }
}

.invalid-feedback {
  position: absolute;
  top: -15px;
  right: 0;
  font-size: 12px;
  color: var(--color-text-error);
}
</style>
