<template>
  <section class="ProjectsPage">
    <div class="Container">
      <h2 class="Title Title--h2 Title--blue ProjectsPage-Title">
        {{ $t('projects.title') }}
      </h2>
      <p class="Text Text--blue ProjectsPage-Text">
        {{ $t('projects.text') }}
      </p>
    </div>
    <div class="Fone ProjectsPage-List">
      <div class="Container">
        <div class="ProjectsPage-Items">
          <div v-for="(item, i) in mergeArrays"
               :key="i"
               class="ProjectsPage-Item">
            <div class="Title--white ProjectsPage-ItemTitle">
              {{ item.title }}
            </div>
            <div class="Text Text--white ProjectsPage-ItemText">
              {{ item.text }}
            </div>
            <img :src="require(`@/assets/img/${item.gif}`)" alt="" class="ProjectsPage-ItemImg">
          </div>
        </div>
      </div>
    </div>

    <Form/>
  </section>
</template>

<script>
import Form from "@/components/Form";

export default {
  name: "ProjectsPage",
  components: {Form},
  computed: {
    projects() {
      return this.$store.getters.getProjects
    },
    mergeArrays() {
      let mergeArr = this.projects.map((info, index) => ({...info, ...this.$t('projects.projects')[index]}));
      return mergeArr
    }
  }
}
</script>

<style lang="scss">
.ProjectsPage {
  padding: 100px 0 0;

  @media(min-width: $screen-xl) {
    padding: 200px 0 50px;
  }

  &-Title {
    margin-bottom: 45px;
    @media(min-width: $screen-xl) {
      margin-bottom: 65px;
    }
  }

  &-Text {
    margin-bottom: 70px;
    text-align: center;
    @media(min-width: $screen-xl) {
      margin-bottom: 152px;
      padding: 0 100px;
    }
  }

  &-List {
    padding: 103px 0 60px;

    @media(min-width: $screen-xl) {
      padding: 195px 0 245px;
    }

  }

  &-Items {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 56px 0;

    @media(min-width: $screen-xl) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 32px 60px;
    }
  }

  &-Item {
    text-align: center;
  }

  &-ItemTitle {
    margin-bottom: 20px;
    font-weight: 800;
    font-size: 22px;
    text-align: center;
    letter-spacing: 0.02em;
  }

  &-ItemText {
    @media(min-width: $screen-xl) {
      padding: 0 80px;
    }
  }

  &-ItemImg {
    width: 250px;
    @media(min-width: $screen-xl) {
      width: 400px;
    }
  }

}
</style>