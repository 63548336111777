<template>
  <div>
    <Header/>
    <About />
    <Projects />
    <Spinner v-if="isVacanciesLoading" />
    <Vacancies
      v-else
      class="Fone"
      :vacancies="vacanciesList"
      theme="dark"
      :isShort="true"
    />
    <Form />
  </div>
</template>

<script>


import Header from "@/components/Header";
import About from "@/components/About";
import Projects from "@/components/Projects";
import Vacancies from "@/components/Vacancies";
import Form from "@/components/Form";
import Spinner from "@/components/Spinner";
import { mapActions, mapState } from 'vuex';

export default {
  name: "HomePage",
  components: {
    Form,
    Vacancies,
    Projects,
    About,
    Header,
    Spinner,
  },
  data() {
    return {
      width: 0,
    }
  },
  computed: {
    ...mapState({
      vacanciesList: 'vacanciesList',
      isVacanciesLoading: 'isVacanciesLoading'
    }),
  },
  methods: {
    ...mapActions({ getVacanciesList: 'getVacanciesList' })
  },
  beforeMount() {
    this.getVacanciesList(4);
  },
  mounted() {
    this.width = window.innerWidth;
  }
}
</script>

<style lang="scss">

</style>
