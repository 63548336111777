<template>
  <section class="Projects">
    <div class="Container">
      <h2 class="Title Title--h2 Title--blue Projects-Title">
        {{ $t('projects.title') }}
      </h2>
      <div class="Projects-Content">
        <div class="Projects-Info">
          <h3 class="Title--h3 Title--blue Projects-InfoTitle">
            {{ $t('projects.subtitle') }}
          </h3>
          <div class="Projects-InfoList">
            <div class="Projects-InfoItem"
                 v-for="(item, i) in $t('projects.projectsList')"
                 :key="i"
            >
              <div class="Text Title--orange Projects-InfoItemTitle">
                {{ item.title }}
              </div>
              <div class="Text Projects-InfoItemText">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
        <img src="@/assets/img/progects.gif" alt="project-img" class="Projects-Img">
      </div>
      <LocalizedLink to="/projects" class="Btn Btn--orange Btn--text-white Projects-Link">
        {{ $t('projects.button') }}
      </LocalizedLink>
    </div>
  </section>
</template>

<script>
import LocalizedLink from "@/components/LocalizedLink";

export default {
  name: "Projects",
  components: {LocalizedLink},
}
</script>

<style lang="scss">
.Projects {
  padding: 46px 0;
  @media(min-width: $screen-xl) {
    padding: 116px 0 100px;
  }

  &-Title {
    margin-bottom: 16px;
    @media(min-width: $screen-xl) {
      margin-bottom: 35px;
    }
  }

  &-Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(min-width: $screen-xl) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &-InfoTitle {
    margin-bottom: 11px;
    @media(min-width: $screen-xl) {
      margin-bottom: 36px;
    }
  }

  &-InfoList {
    margin-bottom: 33px;
    @media(min-width: $screen-xl) {
      margin-bottom: 0;
    }
  }

  &-InfoItem {
    margin-bottom: 16px;
    @media(min-width: $screen-xl) {
      margin-bottom: 30px;
    }
  }

  &-InfoItemTitle {
    margin-bottom: 8px;
    padding-left: 16px;
    position: relative;
    font-weight: 800;

    &:before {
      content: "";
      position: absolute;
      width: 3px;
      height: 3px;
      top: 50%;
      left: 6px;
      transform: translate(0, -50%);
      border-radius: 50%;
      background-color: var(--color-bg-main);
    }

    @media(min-width: $screen-xl) {
      padding-left: 34px;
      font-size: 22px;
      &:before {
        width: 6px;
        height: 6px;
        left: 14px;
      }
    }
  }

  &-Img {
    margin-bottom: 19px;
    @media(min-width: $screen-xl) {
      margin-bottom: 0;
    }
  }

  &-Link {
    padding: 25px 88px 23px;
    position: relative;
    left: 50%;
    transform: translate(-50%,0);

    @media(min-width: $screen-xl) {
      padding: 21px 83px 19px;
      top: -90px;
      left: 0;
      transform: translate(0,0);
    }
  }

}
</style>