<template>
  <section class="About">
    <div class="About-Content Fone About-Content--top">
      <div class="Container">
        <h2 class="Title  Title--h2 Title--orange About-Title">
          {{ $t('about.title') }}
        </h2>
        <p
            v-for="(text, i) in $t('about.text1')"
            :key="i"
            class="Text Text--white About-Text"
            v-html="text"
        ></p>
      </div>
    </div>
    <div class="About-LogosBox">
      <div class="Container">
        <div class="About-Logos">
          <div class="About-LogoBox"
               v-for="(info, i) in logos"
               :key="i"
               :class="info.class"
          >
            <img :src="require(`@/assets/img/${info.logo}`)" alt="" class="About-LogoIcon">
            <div class="Text--white About-LogoText">
              {{ info.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="About-Content Fone About-Content--bottom">
      <div class="Container">
        <p
            v-for="(text, k) in $t('about.text2')"
            :key="k"
            class="Text Text--white About-Text"
            v-html="text"
        ></p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      logos: [
        {class: "Symfony", logo: "simfony.svg", text: "Symfony"},
        {class: "Go", logo: "go.svg", text: "Go"},
        {class: "Python", logo: "python.svg", text: "Python"},
        {class: "MySQL", logo: "mysql.svg", text: "MySQL"},
        {class: "Clickhouse", logo: "clickhouse.svg", text: "Clickhouse"},
        {class: "Redis", logo: "redis.svg", text: "Redis"},
        {class: "Vue", logo: "vue.svg", text: "Vue.js"},
        {class: "Tarantool", logo: "tarantool.svg", text: "Tarantool"},
        {class: "Docker", logo: "docker.svg", text: "Docker"},
        {class: "Kubernetes", logo: "kubernets.svg", text: "Kubernetes"},
        {class: "Git", logo: "git.svg", text: "Git"},
        {class: "Cicd", logo: "cicd.svg", text: "CI/CD"},
      ]
    }
  },
}
</script>

<style lang="scss">
.About {

  &-Content--top {
    padding: 40px 0 26px;

    @media(min-width: $screen-xl) {
      margin-bottom: -100px;
      padding: 80px 0 140px;
    }
  }

  &-Content--bottom {
    padding: 36px 0;

    @media(min-width: $screen-xl) {
      padding: 170px 0 70px;
    }
  }

  &-Title {
    margin-bottom: 28px;

    @media(min-width: $screen-xl) {
      margin-bottom: 47px;
    }
  }

  &-Text {
    @media(min-width: $screen-xl) {
      width: 1076px;
    }
  }

  &-LogosBox {
    //background-color: var(--color-bg-main2);
    position: relative;

    &:before {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      background-color: var(--color-bg-main2);
      transform: rotateZ(-3.5deg) skew(-3.5deg);
    }
    @media(min-width: $screen-xl) {
      //background-color: var(--color-body);
      &:before {
        display: none;
      }
    }
  }

  &-Logos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;

    @media(min-width: $screen-xl) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      grid-gap: 0;
    }
  }

  &-LogoBox {
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: var(--color-bg-main);
    border-radius: 50%;
    align-self: center;
    justify-self: center;

    @media(min-width: $screen-xl) {
      width: 148px;
      height: 148px;
      margin: 0 28px;
    }
  }

  &-LogoIcon {
    @media(min-width: $screen-xl) {

    }
  }

  &-LogoText {
    margin-top: 5px;
    font-size: 10px;

    @media(min-width: $screen-xl) {
      margin-top: 10px;
      font-size: 16px;
    }
  }

  .Symfony {
    @media(min-width: $screen-xl) {
      bottom: -51px;
    }

    img {
      width: 22px;
      @media(min-width: $screen-xl) {
        width: 41px;
      }
    }
  }

  .Go {
    @media(min-width: $screen-xl) {
      bottom: -35px;
    }

    img {
      width: 40px;
      @media(min-width: $screen-xl) {
        width: 73px;
      }
    }
  }

  .Python {
    @media(min-width: $screen-xl) {
      bottom: -22px;
    }

    img {
      width: 52px;
      @media(min-width: $screen-xl) {
        width: 96px;
      }
    }
  }

  .MySQL {
    @media(min-width: $screen-xl) {
      bottom: -9px;
    }

    img {
      width: 45px;
      @media(min-width: $screen-xl) {
        width: 82px;
      }
    }
  }

  .Clickhouse {
    @media(min-width: $screen-xl) {
      bottom: 5px;
    }

    img {
      width: 26px;
      @media(min-width: $screen-xl) {
        width: 48px;
      }
    }
  }

  .Redis {
    @media(min-width: $screen-xl) {
      bottom: 24px;
    }

    img {
      width: 27px;
      @media(min-width: $screen-xl) {
        width: 49px;
      }
    }
  }

  .Vue {
    @media(min-width: $screen-xl) {
      top: 105px;
    }

    img {
      width: 30px;
      @media(min-width: $screen-xl) {
        width: 55px;
      }
    }
  }

  .Tarantool {
    @media(min-width: $screen-xl) {
      top: 87px;
    }

    img {
      width: 36px;
      @media(min-width: $screen-xl) {
        width: 66px;
      }
    }
  }

  .Docker {
    @media(min-width: $screen-xl) {
      top: 73px;
    }

    img {
      width: 33px;
      @media(min-width: $screen-xl) {
        width: 61px;
      }
    }
  }

  .Kubernetes {
    @media(min-width: $screen-xl) {
      top: 61px;
    }

    img {
      width: 28px;
      @media(min-width: $screen-xl) {
        width: 52px;
      }
    }
  }

  .Git {
    @media(min-width: $screen-xl) {
      top: 47px;
    }

    img {
      width: 30px;
      @media(min-width: $screen-xl) {
        width: 56px;
      }
    }
  }

  .Cicd {
    @media(min-width: $screen-xl) {
      top: 30px;
    }

    img {
      width: 44px;
      @media(min-width: $screen-xl) {
        width: 81px;
      }
    }
  }

}
</style>