<template>
<section class="ContactsPage">
  <Form />
  <Contacts />
</section>
</template>

<script>
import Form from "@/components/Form";
import Contacts from "@/components/Contacts";
export default {
  name: "ContactsPage",
  components: {Contacts, Form}
}
</script>

<style lang="scss">
.ContactsPage {
  padding: 50px 0 0;

  @media(min-width: $screen-xl) {
    padding: 70px 0 0;
  }

}
</style>